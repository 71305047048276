import styled from 'styled-components';

export const TextSection = styled.div`
  margin-bottom: ${(props) => (props.bigBottomMargin ? '3rem' : '2rem')};
  position: relative;
  z-index: 50;

  @media (min-width: 1024px) {
    margin-bottom: ${(props) => (props.bigBottomMargin ? '5rem' : '2.8rem')};
  }

  max-width: 630px;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
