import styled from 'styled-components';

export const ListItem = styled.li`
  line-height: 3.1rem;
  font-size: 1.8rem;
  font-weight: 300;
`;

export const List = styled.ul`
  padding: 0 0 0 2rem;
  margin: 0;
`;
