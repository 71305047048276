import React from 'react';
import SanityBlockContent from '@sanity/block-content-to-react';
import { Bold, Paragraph } from '../components/Paragraph';
import { List, ListItem } from '../components/List';

export const serializers = {
  types: {
    block: (props) => {
      const { style = 'normal' } = props.node;

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, '');
        return React.createElement(
          style,
          { className: `heading-${level}` },
          props.children
        );
      }
      if (props.children.length >= 1) {
        return <Paragraph>{props.children}</Paragraph>;
      }
      // Fall back to default handling
      return SanityBlockContent.defaultSerializers.types.block(props);
    },
  },
  h1: (props) =>
    props.type === 'bullet' ? (
      <h1>{props.children}</h1>
    ) : (
      <h2>{props.children}</h2>
    ),
  list: (props) =>
    props.type === 'bullet' ? (
      <List>{props.children}</List>
    ) : (
      <List>{props.children}</List>
    ),
  listItem: (props) =>
    props.type === 'bullet' ? (
      <ListItem>{props.children}</ListItem>
    ) : (
      <ListItem>{props.children}</ListItem>
    ),

  marks: {
    strong: (props) => <Bold>{props.children}</Bold>,
    em: (props) => <em>{props.children}</em>,
    code: (props) => <code>{props.children}</code>,
  },
};
